import React from 'react';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
 
const Switcher = () => {
    const {languages, originalPath} = useI18next();
    return (
        <>
            <div className="d-flex justify-content-end">
                <ul className="languages list-group list-group-horizontal">
                {languages.map((lng) => (
                    <li className="list-group-item bg-transparent border-0" key={lng}>
                        <Link className="text-decoration-none text-dark text-uppercase fw-bold" to={originalPath} language={lng}>
                            {lng}
                        </Link>
                    </li>
                ))}
                </ul>
            </div>
        </>
    );
};
 
export default Switcher;