import * as React from "react";
import CodeCheck from "../components/codeCheck";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from 'gatsby';
import Switcher from "../components/swticher";
//Images
import Image from "react-bootstrap/Image";
import falci_baci from "../images/falcibaci.png";
import kaave from "../images/kaave_logo.png";
// import n11 from "../images/n11.png";
// import hepsiburada from "../images/hepsiburada.png";
// import gittigidiyor from "../images/gittigidiyor.png";


//locale lang Graphql
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// markup
const IndexPage = () => {

  const {t} = useTranslation();
  const appstore = t('store_ios');
  const playstore = t('store_google');
  const huawei = t('store_huawei');

  return (
    <main style={{ backgroundColor: "#fef6ea" }}>
      <Helmet title="Kaave Kod - Falcı Bacı ve Arkadaşları" />
      <section>
        <div className="container">
          <div className="row">
            <div id="main1" className="col-lg">
              <Image src={falci_baci} fluid />
            </div>
            <div id="main2" className="col-lg text-center">
              <Switcher/>
              <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                <Image style={{ width: "80%" }} src={kaave} fluid />
                <h1 className="fw-bold my-5">
                  <Trans>KAAVE KOD'unu aşağıdaki alana gir ve hediyeni kolayca yükle!</Trans>
                </h1>
                <Trans> <CodeCheck/> </Trans>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="background-satis">
        <div className="container">
          <button style={{textDecoration:"none", color:"black"}} className="w-100 bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <h1 className="w-100 display-4 py-5 text-center fw-bold">
              {/*<Trans>KAAVE KOD</Trans> <u style={{textDecorationColor:"#DB6704"}}><Trans>SATIN AL</Trans></u>*/}
              &nbsp;&nbsp;&nbsp;
            </h1>
          </button>
        </div>
      </section>

      {/*<section>*/}
      {/*  <div className="text-center offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">*/}
      {/*    <div className="text-center offcanvas-header">*/}
      {/*      <h5 className="offcanvas-title" id="offcanvasBottomLabel"></h5>*/}
      {/*      <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>*/}
      {/*    </div>*/}
      {/*    <div className="offcanvas-body small">*/}
      {/*      <h4 id="display-5" className="mb-5"><Trans>Kaave Kod'ları aşağıdaki satış noktalarından temin edebilirsin!</Trans></h4>*/}
      {/*      <div className="col my-3  ">*/}
      {/*        <a style={{ textDecoration: "none", color: "black" }} href="https://kaave.to/store-n11" rel="nofollow noreferrer" target="_blank">*/}
      {/*          <Image className="img-w" style={{width:"20%"}} src={n11} fluid />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*      <div className="col my-3">*/}
      {/*        <a style={{ textDecoration: "none", color: "black" }} href="https://kaave.to/store-hepsiburada" rel="nofollow noreferrer" target="_blank">*/}
      {/*          <Image className="img-w" style={{width:"20%"}} src={hepsiburada} fluid />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*      <div className="col my-3">*/}
      {/*        <a style={{ textDecoration: "none", color: "black" }} href="https://kaave.to/store-gittigidiyor" rel="nofollow noreferrer" target="_blank">*/}
      {/*          <Image className="img-w" style={{width:"20%"}} src={gittigidiyor} fluid />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section>
        <div className="container mt-3">
          <div className="text-center">
            <a style={{ textDecoration: "none", color: "black" }} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <h3 className="fw-bold">
                <Trans>KAAVE KOD HAKKINDA YARDIM İSTERSEN BURAYA TIKLAYABİLİRSİN!</Trans>
              </h3>
            </a>
            <div className="collapse" id="collapseExample">
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-transparent">
                  <h4><Trans>Kaave Kod'umu girdim ama bana verilen linke tıkladığımda Kaave web sitesi karşıma çıkıyor?</Trans></h4>
                  <p><Trans>Kodunu yüklerken Kaave uygulamasının kurulu olduğu cep telefonundaki mobil tarayıcı ile giriş yapman gerekiyor.
                    Mobil tarayıcın ile kodunu girip karşına çıkan butona tıkladığında hediyelerin otomatik olarak hesabına yüklenecek.</Trans></p>
                </li>
                <li className="list-group-item bg-transparent">
                  <h4><Trans>Kaave Kod'umu yüklerken Kaave uygulaması açıldı ama kodum yüklenmedi?</Trans></h4>
                  <p><Trans>Kodunu yükleyebilmek için en güncel Kaave uygulamasını kullanman gerekiyor. Telefonundaki uygulama mağazasından Kaave uygulamasını
                    güncelleyerek tekrar deneyebilirsin.</Trans></p>
                </li>
                <li className="list-group-item bg-transparent">
                  <h4><Trans>Telefonumda Kaave uygulaması yoktu ve kodumu yüklerken indirdim. Ancak uygulama açıldığında kodum yüklenmedi?</Trans></h4>
                  <p><Trans>Kaave uygulamasını kurduktan sonra kodunu yükleyebilmek için uygulama içinde giriş yaparak kolayca bir hesap oluşturman gerekiyor.
                  Hesap oluşturduktan sonra bu sayfadan kodunu tekrar girip yükleyebilirsin.</Trans></p>
                </li>
                <li className="list-group-item bg-transparent">
                  <h4><Trans>Kaave Kod'umu benden başkası kullanabilir mi?</Trans></h4>
                  <p><Trans>Kaave Kod isme tanımlı bir kod değildir. Dilersen Kaave Kod'unu başkasına vererek onun kullanmasını sağlayabilirsin.</Trans></p>
                </li>
                <li className="list-group-item bg-transparent">
                  <h4><Trans>Yukarıdakilerin hepsini denedim ama kodum hala yüklenmiyor?</Trans></h4>
                  <p><Trans>Uygulama içinden bize bir yardım talebi gönderebilirsen ekibimiz sana hemen yardımcı olacak. Uygulamadaki yardım bölümüne giderek
                    kolayca bir yardım talebi oluşturabilirsin. Bize gönderdiğin mesajda kodunu yazmayı unutma!</Trans></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="text-center pb-5">

            <p className="fs-4 py-5">
              <Trans>Kaave uygulamasını hemen indir, gününe eğlence katma fırsatını kaçırma!</Trans>
            </p>
            <div className="row">
              <div className="col">
                <a href="https://itunes.apple.com/tr/app/kaavefali/id505658864?l=tr" target="_blank" rel="noreferrer">
                  {" "}
                  <Image className="ratio ratio-21x9" src={appstore} fluid />{" "}
                </a>
              </div>
              <div className="col">
                <a href="https://play.google.com/store/apps/details?id=com.didilabs.kaavefali&hl=tr" target="_blank" rel="noreferrer">
                  {" "}
                  <Image className="ratio ratio-21x9" src={playstore} fluid />{" "}
                </a>
              </div>
              <div className="col">
                <a href="https://appgallery.cloud.huawei.com/#/app/C102523619" target="_blank" rel="noreferrer">
                  {" "}
                  <Image className="ratio ratio-21x9" src={huawei} fluid />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#f3971a" }}>
        <div className="container">
          <div className="d-flex justify-content-center py-5">
            <Image style={{ width: "20%" }} src={kaave} fluid />
          </div>
          <div className="row">
            <div className="col d-flex justify-content-start">
              <h3>KAAVE</h3>
            </div>
            <div className="col d-flex justify-content-center">
              <h3><Trans>DÜNYADA KAAVE</Trans></h3>
            </div>
            <div className="col d-flex justify-content-end">
              <h3><Trans>TAKİP ET</Trans></h3>
            </div>

            <hr />

            <div className="col">
              <div className="row d-flex justify-content-start">
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://apps.apple.com/tr/app/kaavefali/id505658864?l=tr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p><Trans>iOS uygulamasını indir</Trans></p>
                </a>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://play.google.com/store/apps/details?id=com.didilabs.kaavefali&hl=tr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p><Trans>Android uygulamasını indir</Trans></p>
                </a>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://destek.kaavefali.com/support/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p><Trans>Yardım</Trans></p>
                </a>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.kaaveapp.com/tr/usage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p><Trans>Kullanım Şartları</Trans></p>
                </a>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="row">
                <a
                  className="d-flex justify-content-center"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.kaaveapp.com/tr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Türkçe</p>
                </a>

                <a
                  className="d-flex justify-content-center"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.kaaveapp.com/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>English</p>
                </a>

                <a
                  className="d-flex justify-content-center"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.kaaveapp.com/ar"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>العربية</p>
                </a>

                <a
                  className="d-flex justify-content-center"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.kaaveapp.com/el"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Ελληνικά</p>
                </a>

                <a
                    className="d-flex justify-content-center"
                    style={{ textDecoration: "none", color: "black" }}
                    href="https://www.kaaveapp.com/es"
                    target="_blank"
                    rel="noreferrer"
                >
                  <p>Español</p>
                </a>

                <a
                    className="d-flex justify-content-center"
                    style={{ textDecoration: "none", color: "black" }}
                    href="https://www.kaaveapp.com/it"
                    target="_blank"
                    rel="noreferrer"
                >
                  <p>Italiano</p>
                </a>
              </div>
            </div>
            <div className="col d-flex justify-content-end">
              <div className="row">
                <a
                  className="d-flex justify-content-end"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.facebook.com/kaavefali"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p> 
                    <svg xmlns="" width="20" height="20" fill="currentColor" className="bi bi-facebook me-2" viewBox="0 0 16 16">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                    </svg>
                    Facebook</p>
                </a>
                <a
                  className="d-flex justify-content-end"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://twitter.com/kaavefali"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p>
                    <svg xmlns="" width="20" height="20" fill="currentColor" className="bi bi-twitter me-2" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                    </svg>
                    Twitter</p>
                </a>
                <a
                  className="d-flex justify-content-end"
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.instagram.com/falcibaci/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p>
                    <svg xmlns="" width="20" height="20" fill="currentColor" className="bi bi-instagram me-2" viewBox="0 0 16 16">
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                    </svg>
                    Instagram</p>
                </a>
                <a
                  className="d-flex justify-content-end"
                  style={{ textDecoration: "none", color: "black" }}
                  href="http://blog.kaavefali.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p> 
                  <svg xmlns="" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill me-2" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                  </svg>
                  Blog</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="py-5 text-center text-muted">
            <p><Trans>Her hakkı saklıdır. 2012-2024, didiLabs</Trans></p>
            <p><Trans>Kaave markası ve uygulamadaki tüm karakterler Didilabs BV'nin tescilli ticari markalarıdır.</Trans></p>
            <p><Trans>İzinsiz kullanılması yasaktır.</Trans>
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;
