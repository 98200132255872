import React, {useRef, useState} from 'react';
import {useForm} from "react-hook-form"
import axios from "axios";
import SpinnerSVG from '../images/spinner.svg'
import "../styles/form.css"
import { Trans } from 'react-i18next';
import {useTranslation} from "gatsby-plugin-react-i18next";

const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
};

export default function CodeCheck() {

    const {handleSubmit} = useForm()
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [shortUrl, setShortUrl] = useState('');
    const {t} = useTranslation();

    const loader = useRef(null)
    const codeForm = useRef(null)
    const codeLink = useRef(null)

    const handlePost = () => {
        setErrorMessage("")

        if (!/^[0-9A-Za-z]{10}$/.test(code)) {
            setErrorMessage(t("10 haneli bir kod girmen gerekiyor."))
            return
        }

        loader.current.style.display = "flex"

        axios({
            method: 'post',
            url: process.env.GATSBY_PARS_ENDPOINT_URL,
            timeout: 5000,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                code: code,
                platform: "KF"
            }
        }).then(
            (response) => {
                if (process.env.GATSBY_DEBUG === true) {
                    console.log(response)
                }

                if (response.data.success) {
                    setErrorMessage('')
                    setShortUrl(response.data.data.consumeUrl)
                    codeForm.current.style.display = "none"
                    codeLink.current.style.display = "flex"
                } else {
                    setShortUrl('')
                    if (process.env.GATSBY_DEBUG === true) {
                        setErrorMessage("Check failed: " + response.data.message)
                    } else {
                        setErrorMessage(t("Hatalı kod girişi yaptın"))
                    }
                }

                loader.current.style.display = "none"
            }
        ).catch((error) => {
                if (process.env.GATSBY_DEBUG === true) {
                    setErrorMessage("Error occurred: " + error.toString())
                } else {
                    setErrorMessage(t("Kod kontrol edilemedi!"))
                }

                loader.current.style.display = "none"

                if (process.env.GATSBY_DEBUG === true && error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            }
        )
    }

    return (
        <>
            <form className="code-entry" id="___codeForm" ref={codeForm} onSubmit={handleSubmit(handlePost)}>
                <label htmlFor="code">
                    <input
                        className="fw-bold"
                        type="text"
                        id="code"
                        value={code}
                        onInput={toInputUppercase}
                        onChange={event => setCode(event.target.value)}
                        placeholder="XXXXXXXXXX"
                        maxLength="10"
                    />
                </label>
                <button id="code-button" type="submit"><b><Trans>KODU GİR</Trans></b></button>
                <div style={{marginTop: '1em', color: 'red'}}>
                    {errorMessage ? errorMessage : ""}
                </div>
                <div
                    key={`loader`}
                    id="___loader"
                    ref={loader}
                    style={{
                        alignItems: "center",
                        backgroundColor: "#F2F2F2CC",
                        display: "none",
                        justifyContent: "center",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 100,
                    }}
                >
                    <img
                        src={SpinnerSVG}
                        alt="Loading"
                        width="150"
                        height="150"
                    />
                </div>
            </form>
            <div
                id="___codeLink"
                className="w-100 p-3 justify-content-center align-items-center"
                ref={codeLink}
                style={{
                    marginTop: '0.25em',
                    marginBottom: '4em',
                    display: 'none'
                }}
            >
                <a href={shortUrl ? shortUrl : "#"}>
                    <button id="link-button" type="submit"><b><Trans>KODU YÜKLE</Trans></b></button>
                </a>
            </div>
        </>
    );
}
